import React from 'react';

const Footer = () => {
    return (
        <div className="ln-footer">
            <div className="ln-footer-social">
                <a className="is-telegram" href="https://x.com/subShintoken" target="_blank" rel="noopener noreferrer" title="X">
                    <div className="icon">
                        <img src="/img/x.svg" alt="X"/>
                    </div>
                </a>
                <a className="is-telegram" href="https://t.me/subshin_token_chat" target="_blank" rel="noopener noreferrer" title="Telegram Chat">
                    <div className="icon">
                        <img src="/img/telegram.svg" alt="Telegram"/>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default Footer;
