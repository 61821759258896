import React, { useState } from "react";

const CopyToClipboard = ({ textToCopy }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        // Copy the text to the clipboard
        navigator.clipboard.writeText(textToCopy).then(() => {
            // Show the copied notification
            setIsCopied(true);

            // Hide the notification after 2 seconds
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        });
    };

    return (
        <div>
            {/* Icon for copying */}
            <div className="icon" onClick={handleCopy}>
                <img src="/img/copy.svg" alt="Copy" />
            </div>

            {/* Notification */}
            {isCopied && (
                <ol className="notification-wrapper" data-notivue-align="top" aria-label="Notifications">
                    <li className="notification-item" tabIndex="-1" data-notivue-id="8" aria-setsize="1" aria-posinset="1">
                        <div className="Notivue__enter" aria-label="Text copied!" tabIndex="-1" data-notivue-container="8">
                            <div className="notification-content">
                                <div className="Notivue__notification notifications" data-notivue="promise-resolve" data-notivue-has-title="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" aria-hidden="true" fill="currentColor" className="Notivue__icon">
                                        <path d="M6,-0c-3.308,-0 -6,2.692 -6,6c-0,3.308 2.692,6 6,6c3.308,-0 6,-2.692 6,-6c-0,-3.308 -2.692,-6 -6,-6Zm3.123,3.989l-3.877,4.616c-0.086,0.102 -0.213,0.162 -0.346,0.164l-0.008,0c-0.131,0 -0.256,-0.055 -0.343,-0.153l-1.662,-1.846c-0.081,-0.085 -0.126,-0.199 -0.126,-0.316c0,-0.254 0.209,-0.462 0.462,-0.462c0.135,0 0.263,0.059 0.35,0.161l1.307,1.451l3.536,-4.209c0.087,-0.101 0.215,-0.159 0.349,-0.159c0.253,-0 0.461,0.208 0.461,0.461c0,0.107 -0.036,0.21 -0.103,0.292Z"></path>
                                    </svg>
                                    <div className="Notivue__content" aria-live="polite" role="status" aria-atomic="true">
                                        <p className="Notivue__content-message">Text copied!</p>
                                    </div>
                                    <button className="Notivue__close" aria-label="Close" type="button" tabIndex="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="Notivue__close-icon">
                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            )}
        </div>

    );
};

export default CopyToClipboard;