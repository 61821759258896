import React, {useState} from "react";
import './App.css';
import Header from "./components/Header";
import SubShinContent from "./components/SubShinContent";
import TradeModel from "./components/TradeModel";
import Roadmap from "./components/Roadmap";
import Footer from "./components/Footer";

function App() {
    const [isModalVisible, setModalVisible] = useState(false);

    const showModal = () => setModalVisible(true);
    const closeModal = () => setModalVisible(false);

    return (
        <div>
            <main className="ln">
                <div className="ln-inner">
                    <div className="ln-top">
                        <Header/>
                        <SubShinContent showModal={showModal}/>
                    </div>
                    <Roadmap />
                    <Footer />
                    <TradeModel isVisible={isModalVisible} closeModal={closeModal}/>

                </div>
            </main>
        </div>
    );
}

export default App;
