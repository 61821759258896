import React from "react";

const SubShinContent = ({ showModal }) => {
    return (
        <div className="ln-content">
            <div className="ln-content-subshin">
                <div className="ln-content-subshin-image is-1">
                    <picture className="is-subshin-image is-level-2">
                        <img className="img-responsive" src="/img/loader.webp" alt="SubShin Token"/></picture>
                </div>
                <div className="ln-content-subshin-image is-2">
                    <picture className="is-subshin-image is-level-7">
                        <img className="img-responsive" src="/img/loader.webp" alt="SubShin Token"/></picture>
                </div>
                <div className="ln-content-subshin-image is-3">
                    <picture className="is-subshin-image is-level-9">
                        <img className="img-responsive" src="/img/loader.webp" alt="SubShin Token"/></picture>
                </div>
                <div className="ln-content-subshin-image is-4">
                    <picture className="is-subshin-image is-level-11">
                        <img className="img-responsive" src="/img/loader.webp" alt="SubShin Token"/></picture>
                </div>
            </div>
            <h1>Your token- your wealth and power</h1>
            {/*<div className="ln-content-exchanges">*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-binance">*/}
            {/*            <img src="/img/binance.svg" alt="Binance"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-bybit">*/}
            {/*            <img src="/img/bybit.svg" alt="Bybit"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-crypto_com">*/}
            {/*            <img src="/img/crypto_com.svg" alt="Crypto.com"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-okx">*/}
            {/*            <img src="/img/okx.svg" alt="OKX"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-bingx">*/}
            {/*            <img src="/img/bingx.svg" alt="BingX"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-htx">*/}
            {/*            <img src="/img/htx.svg" alt="HTX"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-kucoin">*/}
            {/*            <img src="/img/kucoin.svg" alt="KuCoin"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-gate_io">*/}
            {/*            <img src="/img/gate_io.svg" alt="Gate.io"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-mexc">*/}
            {/*            <img src="/img/mexc.svg" alt="MEXC"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="ln-content-exchange">*/}
            {/*        <div className="exchange-image is-bitget">*/}
            {/*            <img src="/img/bitget.svg" alt="Bitget"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}
            <p>Invest in Future : Endless Opportunities | Gateway to a Decentralized World | Value Meets Vision</p>
            <p className="is-hidden-mobile">Buy upgrades, complete quests, invite friends and become the best</p>
            <div className="ln-content-buttons">
                {/*<div className="ln-content-button" onClick={showModal}>*/}
                {/*    <div className="button button-trade">*/}
                {/*        <span>Trade $SUBS</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="ln-content-button">
                    <a className="button button-trade"
                       href="https://www.pinksale.finance/launchpad/bsc/0x47b0198b870eCF7bBfaFB8303dbF0B758C057D7D"
                       target="_blank" rel="noopener noreferrer">
                        <span>Buy $SUBS</span>
                    </a>
                </div>
                <div className="ln-content-button">
                    <a className="button button-play"
                       href="https://t.me/subshin_airdrop_bot"
                       target="_blank" rel="noopener noreferrer">
                        <span>Play now</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SubShinContent;
