import React from 'react';
import CopyToClipboard from "./CopyToClipboard";

const Header = () => {
    return (
        <div className="ln-header">
            <div className="ln-header-left">
                <div className="ln-header-social">
                    <a
                        className="is-x"
                        href="https://x.com/subShintoken"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="X"
                    >
                        <div className="icon">
                            <img src="/img/x.svg" alt="x"/>
                        </div>
                    </a>
                    <a
                        className="is-telegram"
                        href="https://t.me/subshin_token"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Telegram official channel"
                    >
                        <div className="icon">
                            <img src="/img/telegram.svg" alt="Telegram"/>
                        </div>
                    </a>
                    <p>Join us!</p>
                </div>
            </div>

            <div className="ln-header-center">
                <div className="ln-header-center-logo-container">
                    <div className="ln-header-center-logo">
                        <picture className="is-token">
                            <img className="img-responsive" src="/img/subshin.webp" alt="SubShin Token"/>
                        </picture>
                    </div>
                    <p>SubShin (SUBS)</p>
                </div>
                <div className="ln-header-contract">
                    <div className="ln-header-contract-address">
                        <p>Contract Address</p>
                        <span>0xE6A6D2C420A21110F0bDE60A2Af7BD245152066b</span>
                    </div>
                    <CopyToClipboard textToCopy="0xE6A6D2C420A21110F0bDE60A2Af7BD245152066b" />
                </div>
            </div>

            <div className="ln-header-right">
                <a
                    className="button"
                    href="/docs/SubShin-Whitepaper.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Whitepaper"
                >
                    <p>Whitepaper</p>
                    <div className="icon is-ln-arrow">
                        <img src="/img/arrow.svg" alt="Arrow"/>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default Header;
