import React from 'react';

const TradeModel = ({isVisible, closeModal}) => {
    if (!isVisible) return null; // Hide the modal if not visible

    return (
        <div className={`ln-modal ${isVisible ? 'visible' : ''}`}>
            <div className="ln-modal-bg" onClick={closeModal}></div>
            <div className="ln-modal-inner">
                <div className="ln-modal-close" onClick={closeModal}>
                    <div className="icon">
                        <img src="/img/close.svg" alt="close"/>
                    </div>
                </div>
                <div className="ln-modal-title">Trade $SUBS</div>
                <div className="ln-modal-content">
                    <a className="ln-modal-exchange"
                       href="https://www.binance.com/trade/SUBS_USDT" target="_blank"
                       rel="noopener noreferrer">
                        <div className="ln-modal-exchange-icon">
                            <div className="exchange-image is-binance">
                                <img src="/img/binance.svg" alt="Binance"/>
                            </div>
                        </div>
                        <span>Binance</span></a>
                    <a className="ln-modal-exchange"
                       href="https://www.okx.com/trade-spot/SUBS-usdt" target="_blank"
                       rel="noopener noreferrer">
                        <div className="ln-modal-exchange-icon">
                            <div className="exchange-image is-okx">
                                <img src="/img/okx.svg" alt="OKX"/>
                            </div>
                        </div>
                        <span>OKX</span></a><a className="ln-modal-exchange"
                                               href="https://www.bybit.com/trade/spot/SUBS/USDT" target="_blank"
                                               rel="noopener noreferrer">
                    <div className="ln-modal-exchange-icon">
                        <div className="exchange-image is-bybit">
                            <img src="/img/bybit.svg" alt="Bybit"/>
                        </div>
                    </div>
                    <span>Bybit</span></a><a className="ln-modal-exchange" href="https://www.bitget.com/spot/SUBSUSDT"
                                             target="_blank" rel="noopener noreferrer">
                    <div className="ln-modal-exchange-icon">
                        <div className="exchange-image is-bitget">
                            <img src="/img/bitget.svg" alt="Bitget"/>
                        </div>
                    </div>
                    <span>Bitget</span></a><a className="ln-modal-exchange" href="https://www.gate.io/trade/SUBS_USDT"
                                              target="_blank" rel="noopener noreferrer">
                    <div className="ln-modal-exchange-icon">
                        <div className="exchange-image is-gate_io">
                            <img src="/img/gate_io.svg" alt="Gate.io"/>
                        </div>
                    </div>
                    <span>Gate.io</span></a><a className="ln-modal-exchange"
                                               href="https://www.mexc.com/exchange/SUBS_USDT" target="_blank"
                                               rel="noopener noreferrer">
                    <div className="ln-modal-exchange-icon">
                        <div className="exchange-image is-mexc">
                            <img src="/img/mexc.svg" alt="MEXC"/>
                        </div>
                    </div>
                    <span>MEXC</span></a><a className="ln-modal-exchange"
                                            href="https://trading.bitfinex.com/t/SUBS:USD" target="_blank"
                                            rel="noopener noreferrer">
                    <div className="ln-modal-exchange-icon">
                        <div className="exchange-image">
                            <div className="common-image">
                                <picture>
                                    <source srcSet="/img/bitfinex.webp" type="image/webp"/>
                                    <img
                                        src="/img/bitfinex.png" alt="bitfinex"/>
                                </picture>
                            </div>
                        </div>
                    </div>
                    <span>Bitfinex</span></a><a className="ln-modal-exchange"
                                                href="https://www.kucoin.com/trade/SUBS-USDT" target="_blank"
                                                rel="noopener noreferrer">
                    <div className="ln-modal-exchange-icon">
                        <div className="exchange-image is-kucoin">
                            <img src="/img/kucoin.svg" alt="KuCoin"/>
                        </div>
                    </div>
                    <span>KuCoin</span></a></div>
            </div>
        </div>
    );
}

export default TradeModel