import React, {useState} from 'react';
import { useSpring, animated } from 'react-spring';

const Roadmap = () => {
    const [showPrevious, setShowPrevious] = useState(false);

    const toggleRoadmap = () => {
        setShowPrevious(!showPrevious);
    };

    // Define the animation for the element
    const fadeInProps = useSpring({
        opacity: showPrevious ? 1 : 0,
        transform: showPrevious ? 'translateY(0)' : 'translateY(-10px)',
        visibility: showPrevious ? 'visible' : 'hidden',
        config: { tension: 10, friction: 1 }
    });

    return (
        <div className="ln-roadmap">
            <h2>Roadmap</h2>
            <div className="ln-roadmap-spoiler" onClick={toggleRoadmap}>
                <p>{showPrevious ? 'Hide previous events' : 'Show previous events'}</p>
                <div className={showPrevious ? 'icon is-caret-open' : 'icon'}>
                    <img
                        src='/img/up-down-arrow.svg'
                        alt={showPrevious ? 'Hide' : 'Show'}
                    />
                </div>
            </div>
            <div className="ln-roadmap-inner">
                {showPrevious && (
                    <animated.div
                        className="ln-roadmap-expand-block"
                        style={fadeInProps}
                    >
                        <div className="ln-roadmap-item is-completed">
                            <div className="ln-roadmap-title"><p>Nov 2024</p></div>
                            <div className="ln-roadmap-content">
                                <ul>
                                    <li className="is-completed">Finalize the tokenomics structure</li>
                                    <li className="is-completed">Release the whitepaper</li>
                                </ul>
                            </div>
                        </div>
                    </animated.div>
                )}
                {/* Add more roadmap items here */}
                <div className="ln-roadmap-item">
                    <div className="ln-roadmap-title"><p>Dec 2024</p></div>
                    <div className="ln-roadmap-content">
                        <ul>
                            <li>Launch the official website and social media platforms</li>
                            <li>Initiate strategic partnerships</li>
                            <li>Initiate airdrop campaigns</li>
                        </ul>
                    </div>
                </div>
                <div className="ln-roadmap-item">
                    <div className="ln-roadmap-title"><p>Q1 (Jan - Mar) 2025</p></div>
                    <div className="ln-roadmap-content">
                        <ul>
                            <li>Launch the MVP (Minimum Viable Product) of the platform</li>
                            <li>Conduct a private token sale</li>
                            <li>Integration of external payment systems into the game</li>
                        </ul>
                    </div>
                </div>
                <div className="ln-roadmap-item">
                    <div className="ln-roadmap-title"><p>Q2 (Apr - Jun) 2025</p></div>
                    <div className="ln-roadmap-content">
                        <ul>
                            <li>Launch a staking platform for SUBS token holders</li>
                            <li>List SubShin on major decentralized exchanges</li>
                        </ul>
                    </div>
                </div>
                <div className="ln-roadmap-item">
                    <div className="ln-roadmap-title"><p>Q3 (July - Sep) 2025</p></div>
                    <div className="ln-roadmap-content">
                        <ul>
                            <li>Expand platform features based on MVP feedback</li>
                            <li>Enable DAO governance, empowering token holders to influence the platform’s future</li>
                        </ul>
                    </div>
                </div>
                <div className="ln-roadmap-item">
                    <div className="ln-roadmap-title"><p>Q4 (Oct - Dec) 2025</p></div>
                    <div className="ln-roadmap-content">
                        <ul>
                            <li>Introduce cross-chain bridges for enhanced interoperability</li>
                            <li>Integrate NFT minting and trading features</li>
                            <li>Launch a mobile wallet for simplified access and broader adoption</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;
